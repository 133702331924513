/* You can add global styles to this file, and also import other style files */

@import 'assets/styles/customer-media.css';
@import 'assets/styles/main.css';
@import 'assets/styles/style.css';
@font-face {
    font-family: 'Poppins';
    /*a name to be used later*/
    src: url('assets/fonts//Poppins/Poppins-Regular.ttf');
    /*URL to font*/
}

$dark-blue-color: #334d6e;
$description-text-color: #828282;

@font-face {
    font-family: 'icomoon';
    src: url('assets/fonts/icomoon.eot?h4vqqs');
    src: url('assets/fonts/icomoon.eot?h4vqqs#iefix') format('embedded-opentype'), url('assets/fonts/icomoon.ttf?h4vqqs') format('truetype'),
        url('assets/fonts/icomoon.woff?h4vqqs') format('woff'), url('assets/fonts/icomoon.svg?h4vqqs#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-flight:before {
    content: '\e900';
}

.icon-network:before {
    content: '\e901';
}

.icon-notification:before {
    content: '\e902';
}

.icon-scheduled:before {
    content: '\e903';
}

.icon-scheduled_car:before {
    content: '\e904';
}

.icon-user:before {
    content: '\e905';
}

.icon-Vector:before {
    content: '\e906';
}

.icon-down-arrow:before {
    content: '\e907';
}

.icon-invisibility:before {
    content: '\e908';
}

.icon-Delete:before {
    content: '\e909';
}

.icon-Edit:before {
    content: '\e90a';
}

.icon-bookmark:before {
    content: '\e90c';
}

.icon-delete:before {
    content: '\e90d';
}

.icon-fav:before {
    content: '\e90e';
}

.icon-more_vert:before {
    content: '\e90b';
}

.icon-receipt:before {
    content: '\e90f';
}

.icon-next:before {
    content: '\e910';
}

.icon-left-arrow:before {
    content: '\e911';
}

// .gm-style-iw .gm-style-iw-c{
//     width: 100px;
// }

.gm-style .gm-style-iw-c {
    font-weight: 600;

    padding: 0 !important;

    .gm-ui-hover-effect {
        bottom: 0 !important;
        top: unset !important;
    }

    // driver banner
    .gm-style-iw-d {
        text-align: center !important;
        overflow: auto !important;
        width: auto !important;
        min-width: 100px !important;
    }
}

.gm-style-iw-chr {
    position: absolute !important;
    bottom: -10px !important;
    right: -14px !important;
}
.badge-dark-2 {
    background-color: #323f50;
    color: white;
}

.badge-grey {
    background-color: #00000012;
    padding: 4px 6px;
}

.badge-orange {
    background-color: #c6831f2b;
    padding: 4px 6px;
    color: #e49113;
}

.badge-inactive {
    background-color: #f4433612;
    padding: 4px 6px;
}

.badge-active {
    background-color: #64ec9629;
    padding: 4px 6px;
}

.bg-placeholder-trip {
    border-left: 24px solid transparent;
}

.bg-cyan {
    background: #f1eee7;
}

.bg-grayish-blue {
    background-color: #e6e9ec;
}

.bg-active-trip {
    // background-color: #cdeaff;
    border-left: 24px solid #cdeaff;
}

.bg-inactive-trip {
    // background-color: #ffcdd6;
    border-left: 24px solid #ffcdd6;
}

// =================
//     variables
// =================
$theme-color: #1f8dc6;
$hover-color: #2c71c3;
$scroll-thumb: #adadad;
$scroll-track: #f1f1f1;

$orange-color: #c6831f;
$green-color: #0d8b5e;

@mixin square($size) {
    width: $size;
    height: $size;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.responsive-table-icon {
    .icons {
        font-size: 16px;
    }
    .icons:hover {
        color: black;
    }
}

.form-icon .fa {
    color: #828282;
    opacity: 0.8;
    font-size: 22px;
}

// for ngx bootstrap modal
.bg-none > .modal-content {
    background: none !important;
    border: none;
    width: auto;
    margin: auto;
}

.default_btn {
    background-color: transparent !important;
    min-width: 146px;
    min-height: 28px;
    line-height: 28px !important;
    border: 1px solid #ebeff2;
    border-radius: 5px;
    box-shadow: none;
    font-size: 12px;
    color: #707683 !important;
    text-align: center;
    padding: 0 10px !important;
    font-weight: 400 !important;
    margin-left: 10px;
}

.main_outer .page-header .tab_menu .transition-none.tripsbtn::after,
.transition-none {
    transition: none !important;
}

.tab_a {
    padding: 0 0 15px;
    border: none;
    font-weight: normal !important;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #99a6b7 !important;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 0 !important;
    transition: 0.15s;
    &::after {
        content: '';
        background: #2c71c3;
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 0;
        height: 2px;
        transition: 0.8s;
        border-radius: 4px;
    }
    &:hover {
        background-color: transparent !important;
        color: #2c71c3 !important;
        font-weight: 600 !important;

        &::after {
            width: 100%;
        }
    }
    &.active-page {
        color: #2c71c3 !important;
        font-weight: 600 !important;
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        &::after {
            width: 100%;
        }
    }
}

hr.light {
    margin: 0;
    padding: 0;
}

hr.light-color {
    border-color: #ebeff2;
}

#forget {
    color: #6b6b6b;
}

.pi {
    font-family: 'primeicons' !important;
}

.popup-main-content .signup_btn,
.signup_btn {
    border: none;
    margin-top: 13px;
    position: relative;
    float: left;
    width: 80%;
    background: $theme-color;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    padding: 15px 0px 15px 0px;
    background-image: linear-gradient(90deg, #234369 0%, #16243f 36%, #080514 100%);
}

/** country drop down css **/

.iti {
    position: relative;
    display: inline-block;
    width: 100% !important;
}

.iti__country-list {
    border: inherit !important;
    // max-width: 367px;
    max-width: 353px;
    max-height: 150px;
    // 386px
}

.disable-icon {
    opacity: 0.5;
    pointer-events: none;
}

.fare-breakdown-modal {
    width: 500px;
}

@media screen and (max-width: 1201px) and (min-width: 992px) {
    .iti__country-list {
        max-width: 386px;
    }
}
@media screen and (max-width: 991px) {
    .iti__country-list {
        max-width: 292px;
    }
}

@media screen and (max-width: 767px) {
    .prepaid-popover {
        max-width: auto !important;
        width: auto !important;
    }

    .dropdown,
    .touch-box {
        .placeholder {
            &.small {
                font-size: 11px !important;
            }
        }
    }

    .mobile-none {
        display: none !important;
    }
    .iti__country-list {
        max-width: 437px;
    }
    .fare-breakdown-modal {
        width: auto;
    }

    .custom-position {
        transform: translate3d(288px, 197px, 0px) !important;
    }
}

@media screen and (max-width: 576px) {
    .centered-text {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .main_outer .book_btns .btn {
        min-width: max-content !important;
        font-size: 9px !important;
        padding: 0 6px !important;
    }
    .fare-breakdown-modal {
        width: auto;
    }

    .fare-estimate-border {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .custom-position {
        transform: translate3d(64px, 197px, 0px) !important;
    }
}

#live-track-mobile-field .iti__country-list {
    width: 250px !important;
}

@media (max-width: 1200px) {
    #live-track-mobile-field .iti__country-list {
        width: 285px !important;
        overflow-x: hidden;
    }
}
// html,
// body {
//     height: 100%;
// }

body {
    margin: 0;
    font-size: 1rem !important;
    // font-family: "Open Sans", sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    // background-image: url("/assets/images/pattern.png");
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #000000;
}

.mat-tab-label .mat-tab-label-content {
    text-transform: uppercase;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #1f8dc6;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default,
tr.cursor-default td {
    cursor: default !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background: #f8f9fa;
}

.select-menu-item-style {
    .dropdown-item {
        border-left: 16px solid white;

        &.active-item {
            border-color: #334d6e;
            background-color: #c2cfe0;
        }
    }
}

.vertical-line {
    max-height: 80vh;
    width: 0px;
    border-left: 1px solid #ebeff2;

    &.sharp-line {
        border-left: 1px solid #90a0b7;
    }
}

.touch-border-frame {
    background-color: transparent !important;
    min-width: 146px;
    min-height: 28px;
    line-height: 28px !important;
    border: none;
    border-radius: 6px;
    box-shadow: none;
    font-size: 12px;
    color: #707683 !important;
    text-align: center;
    padding: 0 10px !important;
    font-weight: 400 !important;

    margin-bottom: -5px;
    margin-right: -30px;

    .label {
        color: #2c71c3;
        font-weight: 600;
    }
}

.touch-input-group {
    $border-color: #ebeff2;
    .input-group-prepend label {
        background-color: white;
        border-right: none;
        padding: 2px 4px;
        border-color: $border-color;
        color: #90a0b7 !important;
    }
    .input-append {
        border-left: none;
        border-color: $border-color;
        padding: 2px 4px;
        // min-width: 112px;
        width: 83%;
        color: #90a0b7 !important;
        border-radius: 4px;

        &:focus {
            box-shadow: none;
        }
    }
}

.cd-menu {
    border: none !important;
    left: -46px !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    color: #828282 !important;
    font-size: 13px !important;
    min-width: unset !important;
    border-radius: 4px;

    &.grey-item {
        .cd-item {
            color: #828282 !important;
        }
    }

    .right-0 {
        right: 0 !important;
    }

    &.left-0 {
        left: 0 !important;
    }

    &.left-unset {
        left: unset !important;
    }

    .cd-item {
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .blue.cd-item {
        color: #334d6e;
        font-weight: 600;
    }

    .cd-item:hover {
        background-color: #f5f4f4;
    }
    .cd-item:active {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
    }
}

.env {
    position: fixed;
    bottom: 50%;
    padding: 15px;
    color: #fff;
    z-index: 999;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    right: 0px;
    background-color: #070811;
    border-radius: 4px 0px 0px 4px;
    text-transform: capitalize;
}

.queue {
    position: fixed;
    bottom: 20%;
    padding: 15px;
    color: #fff;
    z-index: 999;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    right: 0px;
    background-color: #070811;
    border-radius: 4px 0px 0px 4px;
    text-transform: capitalize;
}

.queud-sortcut {
    width: 100%;
    height: 100%;
    margin: 0;
}
.queud-sortcut .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
}

/****************************** PrimeNg Style Start *********************************/

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #328fd8 !important;
    color: #ffffff !important;
}

/****************************** PrimeNg Style End *********************************/

.toast-container {
    .ngx-toastr {
        border-radius: 12px !important;
    }

    .notification-toast {
        $normal-space: 10px;
        position: fixed;
        right: $normal-space;
        padding: 0 !important;
        transition-duration: 0.5s;

        &.bottom {
            bottom: $normal-space;
            &:first-child {
                bottom: $normal-space !important;
                right: $normal-space !important;
            }

            &:last-child {
                $stacked-space: 24px;
                bottom: $stacked-space;
                right: $stacked-space;
            }
        }

        &.top {
            top: $normal-space;
            &:first-child {
                top: $normal-space !important;
                right: $normal-space !important;
            }

            &:last-child {
                $stacked-space: 24px;
                top: $stacked-space;
                right: $stacked-space;
            }
        }

        &.bottom,
        &.top {
            &:first-child,
            &:last-child {
                .notification-card {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

#toast-container > .toast-error {
    background-image: none !important;
}

#toast-container > .toast-success {
    background-image: none !important;
}

// @media screen and (max-width: 1400px) {
.iti input,
.iti input[type='text'],
.iti input[type='tel'] {
    height: 32px;
    font-size: 12px;
}
// }

// @media (max-width: 991px) {
//     .main_outer{
//         min-width: 100%!important;
//     }
// }

.page {
    &.page_outer {
        background-color: #fff;
        padding: 50px 0 0 0;
        box-shadow: none;
        margin-bottom: 0;
        border-radius: 0;
        width: 100%;
        max-width: 100%;

        table tr td {
            cursor: pointer;
        }
    }
}

#Trips {
    .main_outer {
        a.tripsbtn:not(.active-page):hover {
            background-color: transparent !important;
            color: #2c71c3 !important;
            font-weight: 600 !important;
            border-color: #2c71c3 !important;
        }
        .exportBtn:hover {
            .light-weight {
                font-weight: normal !important;
            }
        }
    }
}

// MAS COLOR TEXTS

.mas_unassigned {
    color: $orange-color;
}
.mas_assigning {
    color: $theme-color;
}

.mas_accepted {
    color: $green-color;
}

.bg-theme {
    background-color: $theme-color !important;
}
.bg-orange {
    background-color: $orange-color !important;
}
.bg-green {
    background-color: $green-color !important;
}

.mas {
    color: #c6831f;
    font-weight: 600;
    font-size: 14px;
}

// MAS COLOR TEXTS

.mat-icon.send {
    font-size: inherit;
    vertical-align: middle;
    height: unset;
    width: unset;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.no-select,
button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.default_card {
    font-size: 13px;
}

.centered-text {
    margin-left: 30px;
    margin-right: 30px;
}

.detail-padding {
    padding: 16px 34px 17px;
}

.detail-padding-x {
    padding: 0px 34px;
}

.main_outer {
    // width: 100%;
    width: 100vw;
    display: inline-block;
    padding-top: 15px;
    max-height: calc(100vh - 56px);
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    .external-actions {
        button {
            min-width: 146px;
            min-height: 28px;
            line-height: 28px !important;
            padding: 0 10px !important;
        }
    }

    &.pagination {
        height: calc(100vh - 103px);
    }
    .page-header {
        padding-left: 34px;
        margin-bottom: 0 !important;
    }
    .top_head {
        h2 {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.01em;
            color: #334d6e;
            float: left;
        }
        .top_right {
            float: right;
            margin-right: 12px;
            .exportBtn {
                background-color: transparent !important;
                min-width: 146px;
                min-height: 28px;
                line-height: 28px !important;
                border: 1px solid #ebeff2;
                border-radius: 6px;
                box-shadow: none;
                font-size: 12px;
                color: #707683 !important;
                text-align: center;
                padding: 0 10px !important;
                font-weight: 400 !important;
                margin-left: 10px;

                .label {
                    color: #2c71c3;
                    font-weight: 600;
                }
            }
            .search_input {
                display: inline-block;
                margin: 0 0 0 10px;
                input {
                    background-image: url('/assets/images//search_icon.svg');
                    border: 1px solid #ebeff2;
                    line-height: 26px !important;
                    border-radius: 6px;
                    // padding: 0 6px 0 32px;
                    padding: 1px 6px 1px 32px;
                    background-size: 14px;
                    height: auto;
                    // background-position: 7px;
                    background-position: 12px;
                    max-width: 174px;
                    font-size: 12px;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .table {
        &.force-center {
            td,
            th {
                text-align: center !important;
            }
        }
        &.trips {
            border: none !important;
            box-shadow: none;
        }
        thead {
            &.bt-none {
                th {
                    border-top: none !important;
                }
            }
            th {
                min-width: 120px;
                max-width: 300px;

                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: #334d6e;
                opacity: 0.5;
                border-top: 1px solid #ebeff2;
                // padding: 21px 15px;
                // padding: 15px;
                padding: 12px;
                text-align: left !important;
                &:first-child {
                    padding: 12px 15px 12px 35px;
                }
                &:last-child,
                &.tcenter {
                    text-align: center !important;
                    border-bottom: 1px solid #ebeff2 !important;
                }

                &.tleft {
                    text-align: left !important;
                }

                &.medium {
                    min-width: 160px !important;
                }

                &.large {
                    min-width: 220px !important;
                }
            }
            .frow {
                background-color: transparent;
            }
            .frow:hover {
                background: inherit !important;
            }
        }
        tbody {
            border: none;
            // transition: 0.8s;
            // &:hover {
            //     background-color: #f8fafc;
            // }
            &.break-word td {
                word-break: break-all;
            }
            .no-break-word {
                word-break: inherit !important;
            }

            td {
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                color: #828282;
                border: none;
                border-top: 1px solid #ebeff2;
                padding: 12px 15px;
                text-align: left !important;

                &:first-child {
                    padding: 12px 15px 12px 35px;

                    &.align-left {
                        padding-left: 20px !important;
                    }
                }
                &.load_btn {
                    text-align: center !important;
                }
                &:last-child,
                &.tcenter {
                    text-align: center !important;
                }

                &.tleft {
                    text-align: left !important;
                }

                p,
                button {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 19px;
                    color: #828282;
                    &.load-more-btn {
                        color: #ffffff;
                    }
                }
                &.control_icons {
                    a {
                        display: inline-block;
                    }
                }

                &.break-word-format {
                    word-break: break-word;
                }
            }
        }
    }
    #banner {
        button {
            display: none;
        }
    }
    .page-header {
        .tab_menu {
            .tripsbtn {
                @extend .tab_a;
            }
        }
        .sub_tab_menu {
            display: inline-block;
            width: 100%;
            margin: 0 0 26px 0;
            a {
                @extend .tab_a;
            }
        }
    }
    .table {
        span.icon {
            border: none;
            padding: 0;
            text-align: center;
            width: 100%;
            display: inline-block;
            &.highlight:hover {
                color: black;
            }
        }
        // span.icon:hover {
        //     color: black;
        // }
    }
}

.search_outline_input {
    display: inline-block;
    margin: 0 0 0 10px;
    input {
        background-image: url('/assets/images//search_icon.svg');
        border: 1px solid #ebeff2;
        line-height: 26px !important;
        border-radius: 6px;
        // padding: 0 6px 0 32px;
        padding: 1px 6px 1px 32px;
        background-size: 14px;
        height: auto;
        // background-position: 7px;
        background-position: 12px;
        max-width: 174px;
        font-size: 12px;
        background-repeat: no-repeat;

        &:hover,
        &:focus {
            border-color: #2c71c3 !important;
        }
    }
}

.cardDefaultLabel:hover {
    color: black !important;
}

@media only screen and (max-width: 1355px) {
    .main_outer {
        .table {
            thead th,
            tbody td,
            button,
            .default_card {
                font-size: 10px !important;
            }
        }
    }
}

@media only screen and (max-width: 411px) {
    .main_outer {
        .table {
            thead th,
            tbody td,
            button,
            .default_card {
                font-size: 9px !important;
            }
        }
    }
}

.setting_outer {
    padding-left: 35px;
    padding-right: 35px;
    border-top: 1px solid #ebeff2;
    padding-top: 38px;
}

.setting_heading {
    h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: #0a0c15;
        a {
            font-weight: 200;
            font-size: 9px;
            line-height: 12px;
            color: #2c71c3;
            // margin-left: 26px;
            margin-left: 10px;
            vertical-align: middle;
        }
    }
}

.noti_setting {
    max-width: 500px;
    // .media {
    //     margin-top: 21px;
    // }
    .media-body {
        h2 {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #828282;
            margin: 0;
        }
        p {
            font-size: 9px;
            line-height: 12px;
            color: #828282;
            margin: 2px 0 0 0;
            opacity: 0.7;
        }
    }
}

.main_outer {
    .book_btns {
        .btn {
            min-width: 110px;
            min-height: 30px;
            line-height: 30px;
            padding: 0 10px;
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            color: #fff;
            float: none;
            border-radius: 8px;
            border: none;
            padding-top: 2px;
            padding-bottom: 2px;
            &.bookButton {
                background: $theme-color !important;
            }
            &.button-green {
                background: #323f50 !important;
            }
        }
    }
}

.main_outer {
    div.d-image {
        @include square(30px);
        display: inline-block;
    }
    .driver_name {
        display: inline-block;
        vertical-align: top;
        margin: 5px 0 0 11px;
    }
}

.custom_pagination {
    width: 100%;
    text-align: right;

    .ngx-pagination {
        position: sticky;
        padding: 6px;
        bottom: 0;
        background: #ffffff;
        box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.2);
        margin-bottom: 0 !important;
    }

    ul {
        li {
            display: inline-block;
            padding: 5px;
            // padding: 0 5px;
            &:last-child {
                a {
                    .icons {
                        margin: 0 0 0 9px;
                    }
                }
            }
            a {
                font-size: 13px;
                display: inline-block;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #707683;
                &:hover {
                    color: #2c71c3 !important;
                    text-decoration: underline !important;
                }
                &.prev_btn,
                &.next_btn {
                    &:hover {
                        text-decoration: none;
                    }
                }
                .icons {
                    vertical-align: middle;
                    margin: 0 9px 0 0;
                }
            }
        }
    }
}

.custom_pagination_v2 {
    width: 100%;
    text-align: right;
    box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    position: sticky;
    padding: 6px;
    bottom: 0;
    z-index: 1;

    .ngx-pagination {
        margin-bottom: 0 !important;
    }

    ul {
        li {
            display: inline-block;
            padding: 5px;
            &:last-child {
                a {
                    .icons {
                        margin: 0 0 0 9px;
                    }
                }
            }
            a {
                font-size: 13px;
                display: inline-block;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #707683;
                &:hover {
                    color: #2c71c3 !important;
                    text-decoration: underline !important;
                }
                &.prev_btn,
                &.next_btn {
                    &:hover {
                        text-decoration: none;
                    }
                }
                .icons {
                    vertical-align: middle;
                    margin: 0 9px 0 0;
                }
            }
        }
    }
}
.ngx-pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #fff !important;
    border-bottom: solid !important ;
    border-bottom-width: 2px !important;
    // color: #2199e8 !important;
    color: #2c71c3 !important;
    padding-bottom: 0px !important;
    cursor: default;
}
.ngx-pagination .disabled {
    font-size: 13px !important;
    color: #707683 !important;
    cursor: default;
}
.ngx-pagination {
    font-size: 13px !important;
    color: #707683 !important;
    cursor: default;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
    content: '<' !important;
    display: inline-block;
    margin-right: 0.5rem;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
    content: '>' !important;
    display: inline-block;
    margin-left: 0.5rem;
}

.transform-capital {
    text-transform: capitalize;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-26 {
    font-size: 26px;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-normal {
    font-weight: normal !important;
}

.text-orange {
    color: $orange-color;
}

.text-blue {
    color: #1f8dc6 !important;
}

.text-blue2 {
    color: #4ca4e4;
}

.text-grey {
    color: #828282 !important;
}

.text-grey2 {
    color: #707683;
}

.text-blue-secondary {
    color: $hover-color !important;
}

.text-dark-blue {
    color: $dark-blue-color !important;
}

.bg-dark-blue {
    background: $dark-blue-color !important;
}

.text-red {
    color: #c22831;
}

.text-red2 {
    color: #be2222;
}

.text-green {
    color: #0d8b5e;
}

.text-orange {
    color: #c6832d !important;
}

.text-spacegrey {
    color: #99a6b7 !important;
}

.text-space-grey-light {
    color: #90a0b7 !important;
}

.text-space-grey-light2 {
    color: #bcbebf !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-6 {
    border-radius: 6px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.btn-primary-color {
    background-color: $hover-color !important;
}

.btn-dark-blue {
    background-color: $dark-blue-color !important;
}

.btn-mint-green {
    background-color: #4fa1a3;
}

.status-color.green {
    color: #0d8b5e !important;
}

.status-color.blue {
    color: #1f8dc6 !important;
}

.text-buttons.btn {
    color: #2c71c3 !important;
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    line-height: 0 !important;
}

// Touch Styling

.touch-popup,
.touch-ui {
    $secondary-color: #828282;
    $primary-color: #1f8dc6;
    $darkblue-color: #334d6e;
    font-size: 14px;

    .modal-dialog {
        // max-width: fit-content;
        // max-width: 700px;
    }

    .max-modal,
    &.max-modal {
        max-width: 1100px !important;
    }

    .dark-color {
        color: $darkblue-color;
    }

    .grey-color {
        color: $secondary-color;
    }

    .blue-color {
        color: $primary-color;
    }

    .blue-color2 {
        color: #4ca4e4;
    }

    .large-text {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1;
    }

    .medium-text {
        font-size: 16px;
        font-weight: 600;
    }

    .large-medium-text {
        font-size: 20px;
        font-weight: 600;
    }

    .t-margin {
        margin-left: 36px;
        margin-right: 36px;
    }

    .touch-header {
        .row div {
            color: $secondary-color;
            font-weight: 600;
        }

        .active {
            color: $primary-color !important;
            border-bottom: 3px solid $primary-color;
        }
    }

    .bg-light-blue {
        background: #f8fbfc !important;
    }

    .touch-content {
        color: $secondary-color;
        .title {
            font-weight: 800;
        }
        .semiTitle {
            font-weight: 600;
        }
        .subTitle {
            word-break: break-word;
            font-weight: 400;
        }

        img {
            max-width: 500px;
            max-height: 300px;
        }

        table {
            td:nth-child(1) {
                font-weight: 600;
            }
            td {
                padding: 2px;
            }
        }
        .mat-form-field-wrapper {
            padding-bottom: 0 !important;
        }

        .touch-list-item {
            cursor: pointer;
        }
        .touch-list-item:hover {
            background-color: #ebedef;
            border-radius: 8px;
        }

        .touch-input,
        .iti input,
        .iti input[type='text'],
        .iti input[type='tel'] {
            background: #ffffff;
            border: 1px solid #ebeff2;
            box-sizing: border-box;
            border-radius: 4px;
            color: #696f79;
            min-width: 260px;
            font-weight: 600;

            height: 32px !important;
            font-size: 12px !important;

            &.error {
                border-color: #dc3545 !important;
            }
        }
    }

    .touch-bottom,
    .touch-btn {
        button {
            min-width: 125px;
            background-color: #1f8dc6 !important;
            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;
        }
        .font-light {
            font-weight: normal !important;
        }
        .btn-left {
            background-color: #8692a6 !important;
        }
        .btn-right {
            background-color: $darkblue-color !important;
        }

        .btn-right-secondary {
            background-color: #2c71c3 !important;
        }

        .btn-light {
            color: white;
            background-color: #c2cfe0 !important;
        }

        .btn-dark {
            background-color: #323f50 !important;
        }
    }
}

.touch-popover {
    &.width-260 {
        width: 260px;
    }
}

.touch-popover-menu-item {
    padding: 8px 12px;
    color: #334d6e;
    .title {
        font-weight: 600;
        font-size: 13px;
    }
    .subtitle {
        font-size: 10px;
    }
}
.touch-popover-menu-item:hover,
.touch-popover-menu-item.active-item {
    background-color: #c2cfe0;
}

.touch-currency-input,
.touch-light-bg {
    $currency_bg_color: #edeff1;

    background-color: $currency_bg_color;
    border-radius: 4px;
    color: $dark-blue-color;
    .input- .input-group-prepend,
    .input-group-text {
        border: none;
        background-color: $currency_bg_color;
    }
    input {
        &.w-100px {
            width: 100px;
        }

        &.w-50px {
            width: 50px;
        }

        border: none;
        background-color: $currency_bg_color;
    }

    &.bordered {
        border: 1px solid $currency_bg_color;
    }
}

// Paid Label for paid trips ( trip roster )
.paid_label {
    background-color: #96a6b8;
    color: white;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 11px;
    width: max-content;
}

#request_ride_later,
#reschedule,
#select_date_modal,
#reschedule_dispatched {
    div.schedule-date {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        cursor: pointer;
        background-color: white;
        // background: white;

        .text {
            padding: 14px 0px 14px 14px;
        }
        .cal-icon {
            margin-top: -1px;
            margin-right: -1px;
            border-radius: 0 0.25rem 0.25rem 0;
            padding: 12px;
            background-color: #ced4da;
            transition-duration: 0.2s;
        }
        .cal-icon:hover {
            opacity: 0.8;
        }
    }
    input.mat-date-input {
        width: 0px;
        height: 0px;
        position: relative;
        bottom: 12px;
        left: 11px;
    }
}

@media (max-width: 600px) {
    .touch-popup {
        .large-text {
            font-size: 1.2rem;
        }

        .medium-text {
            font-size: 13px;
        }

        .large-medium-text {
            font-size: 16px;
        }

        .touch-bottom button {
            font-size: 10px !important;
        }

        .modal-dialog {
            max-width: 480px;
        }

        .touch-content {
            .fs-13,
            .fs-11 {
                font-size: 9px !important;
            }
        }
    }

    .main_outer div {
        table {
            &.d-image {
                margin-top: 2px;
                width: 20px;
                height: 20px;
                display: inline-block;
            }

            &.driver_name {
                font-size: 8px;
            }
        }
    }
}

.mlr-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.card-modal {
    max-width: 700px;
    .title {
        font-size: 1.6rem;
        font-weight: 600;
        color: #334d6e;
    }

    .btn {
        border-radius: 4px;
        padding: 0px 17px;
        font-size: 0.85rem;
        color: white;
        min-width: 6rem;
        min-height: 2rem;
    }

    .btn-right {
        background-color: #334d6e !important;
    }

    .btn-left {
        background-color: #1f8dc6 !important;
    }
}

/*
custom modal for car base affiliation
*/
.cmodal,
.touch-container {
    $primary-color: #334d6e;
    $secondary-color: #696f79;
    $primary-margin: 28px 42px;
    $background: #f8fbfc;
    font-weight: 600;
    .title {
        color: $primary-color;
        font-size: 22px;
        // font-weight: 600;
        margin: $primary-margin;
    }
    .middle-section {
        // padding: $primary-margin;
        padding: 28px 42px 50px;
        font-size: 13px;
        background-color: $background;
        .left {
            text-align: right;
            color: $primary-color;
        }

        .right {
            color: $secondary-color;
        }

        img {
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
    }

    .bottom-section {
        padding: 22px 42px;
        background-color: $background;
        border-radius: 0.3rem;
        button {
            font-size: 13px;
            border-radius: 6px;
            &.left {
                background-color: $primary-color !important;
            }
            &.right {
                background-color: #2c71c3 !important;
            }
        }
    }

    hr {
        margin: 0;
        padding: 0;
    }

    // Open classes

    .dropdown,
    .touch-box {
        .placeholder {
            color: $secondary-color;
            border: 1px solid #ebeff2;
            padding: 8px 12px;
            border-radius: 4px;
            background-color: white;

            &.small {
                padding: 4px 6px;
                font-size: 13px;
            }
        }

        .dropdown-menu,
        .touch-menu {
            &:not(.auto) {
                transform: translate3d(0px, 35px, 0px) !important;

                &.up {
                    transform: translate3d(0px, -196px, 0px) !important;
                }
            }
            max-height: 250px;
            overflow-y: scroll;
            background: white;
            .dropdown-item,
            .touch-item {
                padding: 8px 12px;
                color: $primary-color;
                .di-title {
                    font-weight: 600;
                    font-size: 13px;
                }
                .di-subtitle {
                    font-size: 10px;
                }

                &:hover,
                &.active-item {
                    background-color: #c2cfe0;
                }
            }

            // custom scroll bar

            &::-webkit-scrollbar {
                width: 12px !important;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

// stripe card input clone

input.soft-input {
    border: none;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
    border-radius: 4px !important;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(210, 217, 224);
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(210, 217, 224);
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(210, 217, 224);
    }
    &:focus {
        box-shadow: 0 1px 3px 0 #e6ebf1 !important;
    }

    &.error {
        border: 1px solid #dc3545 !important;
    }
}

// custom tool tip

.tool {
    position: relative;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
}

.tool:hover::before,
.tool:focus::before {
    transition: all 0.32s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

/*== speech bubble ==*/
.tool::after {
    background: #ffffff;
    border-radius: 8px; //.25em;
    // bottom: 180%;
    bottom: 30px;
    color: #828282;
    content: attr(data-tip);
    margin-left: -22px; // -8.75em;
    padding: 6px 6px; // 1em;
    transition: all 0.32s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateY(50%);
    // width: 9em; //17.5em;
    width: max-content;
    word-break: normal !important;
    max-width: 14rem;
    font-size: 13px !important;
    text-align: center;

    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.tool-right:after {
    margin-left: 22px;
    bottom: -5px;
}

.tool:hover::after,
.tool:focus::after {
    transition: all 0.32s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

@media (max-width: 760px) {
    .tool::after {
        font-size: 0.75em;
        margin-left: -5em;
        width: 10em;
    }
}

.deducted-price {
    text-decoration: line-through;
    font-size: 14px;
}

.booking-model-component {
    .bold {
        font-weight: 600;
    }
    .rb-mid-text {
        color: $dark-blue-color;
        font-weight: 600;
        font-size: 1.05rem;
    }
    .rb-small-size {
        font-size: 0.9rem;
    }
    .rb-large-text {
        color: $dark-blue-color;
        font-weight: 600;
        font-size: 1.875rem;
    }
    .rb-grey-mid-text {
        color: $description-text-color;
        font-size: 0.9rem;
    }
    .rb-blue-small-text {
        font-size: 0.8rem;
        color: #1f8dc6;
    }
    .rb-dark-blue-text {
        color: $dark-blue-color;
        font-size: 0.9rem;
    }
    .original-price {
        text-decoration: line-through;
        color: $dark-blue-color;
        font-weight: 600;
    }
    .rb-detail-table {
        td {
            padding-bottom: 6px;
            // vertical-align: top;
        }
        td:nth-child(1) {
            color: $description-text-color;
            font-size: 0.9rem;
            min-width: 9rem;
            vertical-align: top;
        }
        td:nth-child(2) {
            padding-left: 8px;
            color: $dark-blue-color;
            font-size: 0.9rem;
        }
    }
    .rb-btn {
        .btn {
            border-radius: 4px;
            padding: 0px 12px;
            font-size: 0.91rem;
            min-width: 6rem !important;
            min-height: 2rem !important;
        }
        .rb-btn-dark-blue {
            color: white;
            background-color: $dark-blue-color !important;
        }
        .rb-btn-blue {
            color: white;
            background-color: #1f8dc6;
        }
        .rb-btn-grey {
            color: white;
            background-color: $description-text-color;
        }
    }
    .rb-img {
        position: absolute;
        margin: 3px 20px 17px 0px;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        border-radius: 5px;
    }
    .rb-img-2 {
        position: relative !important;
        height: 82px;
        width: 82px;
        border-radius: 5px;
    }

    .ride-image-zoom {
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        margin: 8px;
        border-radius: 5px;
    }
}

.disabled-text,
.disabled {
    opacity: 0.4 !important;
    pointer-events: none !important;
}

.search-box {
    $secondary-color: #696f79;
    position: sticky;
    top: 0;
    background: white;
    span,
    .input-box {
        font-size: 13px;
        color: $secondary-color;
        border: 1px solid #ebeff2;
        border-radius: 4px;
        padding: 8px 12px;
        input {
            color: $secondary-color;
            // width: 90%;
            width: 88%;
        }
        i {
            color: #c2cfe0;
        }
    }
}
.search-shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.search-box.light {
    // position: sticky;
    // top: 0;
    background: white;

    .grey-placeholder {
        $color: #90a0b7;
        &::placeholder {
            color: $color;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $color;
        }
    }

    span {
        font-size: 13px;
        color: #696f79;
        // border: 1px solid #ebeff2;
        border: 1px solid #c9c9c9cc;
        border-radius: 6px;
        padding: 6px 12px;

        .mat-icon {
            vertical-align: middle;
            font-size: 20px;
            height: 20px;
            width: 20px;
        }

        .fa {
            color: #c2cfe0;
            font-size: 18px;
        }
    }
}

.custom-checkbox {
    margin-top: -3px;
    color: grey;
    .fa-check-square {
        color: #1f8dc6 !important;
    }
}

.card-shadow {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.image-icon {
    &.h-17 {
        height: 17px;
    }
}

.error {
    border-color: #dc3545 !important;
}

.error-text {
    color: #dc3545 !important;
}

// hide ngx bootstrap popover arrow
.bs-popover-bottom {
    margin-top: 0;
}
.popover {
    .arrow {
        display: none;
    }
    .popover-body {
        padding: 0;
    }
    box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
    border: none !important;
    border-radius: 4px;

    input[type='radio'] {
        -webkit-appearance: radio !important;
        &.dark-blue {
            accent-color: $dark-blue-color;
        }
    }
}

.w-260 {
    width: 260px;
}

.w-350 {
    max-width: 350px;
    width: 350px;
}

.prepaid-popover,
.w-700 {
    max-width: 700px;
    width: 700px;
}

.w-100vw {
    width: 100vw;
    max-width: 100vw;
}

.h-100vh {
    height: 100vh;
}

.transform-none {
    transform: none !important;
}

.va-middle {
    vertical-align: middle;
}

// custom toggl
// custom toggl

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $dark-blue-color;
    background-color: $dark-blue-color;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $dark-blue-color;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: none;
}

.custom-control-label {
    padding-top: 2px;
}

img.refresh {
    height: 24px;
    width: 24px;

    &.refresh-action {
        animation-name: spin;
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.text-dark-grey {
    color: #5e6c7d;
}

.rideBroker-label {
    color: #323f50;
    font-weight: bold;
}

.view-in-live-map {
    white-space: nowrap;
}

.control-box {
    // border:1px solid grey;
    border: 0.5px solid #dbdada;
    border-radius: 5px;
    font-size: 12px;
    color: grey;
    font-weight: normal;
    // padding: 5px;
    padding: 2px;

    &.light-padding {
        padding: 1px !important;
    }

    span {
        cursor: pointer;
        padding: 5px;
    }
    span.highlight:hover {
        color: black;
    }
    .span-active {
        padding: 2px 8px;
        color: white;
        background-color: #1f8dc6;
        border-radius: 5px 0px 0px 5px;
    }
    .span-inactive {
        padding: 2px 8px;
        color: white;
        background-color: #828282;
        border-radius: 0px 5px 5px 0px;
    }

    .span-active-dark-blue {
        padding: 0px 6px;
        color: white;
        background-color: $dark-blue-color;
        border-radius: 5px 0px 0px 5px;
    }

    .span-inactive-light {
        padding: 0px 6px;
        // color: white;
        background-color: #75737329;
        border-radius: 0px 5px 5px 0px;
    }
}

.touch-tel-input {
    .country-dropdown {
        width: 263px !important;
        border: 1px solid #ebeff2 !important;
    }
    &.error {
        .iti input,
        .iti input[type='text'],
        .iti input[type='tel'] {
            border-color: #dc3545 !important;
        }
    }
}
.mat-progress-bar-fill:after {
    background-color: #f1b457;
}

.mat-progress-bar-buffer {
    background-color: white !important;
}

.alert-driver {
    // padding: 4px;
    padding: 1px 4px 2px;
    transition-duration: 80ms;
    &:hover {
        border-radius: 6px;
        background-color: white;
    }
}

button.outline-btn {
    // font-size: 13px;
    // font-weight: 400;
    // color: #707683;
    // border-radius: 4px;
    // border: 1px solid #EBEFF2;

    background-color: transparent !important;
    // line-height: 28px !important;
    border: 1px solid #ebeff2;
    border-radius: 6px;
    font-size: 13px;
    color: #707683 !important;

    // padding: 0 10px !important;
    // font-weight: 400 !important;
    // margin-left: 10px;

    &:hover {
        background-color: transparent !important;
        color: #2c71c3 !important;
        font-weight: 600 !important;
        border-color: #2c71c3 !important;
    }
}

table.touch-table {
    tr td {
        padding: 12px 0px;
    }
    td:nth-child(1) {
        color: #99a6b7;
        min-width: 13rem;
    }
    td:not(:nth-child(1)) {
        color: #334d6e;
    }
}

.notes_popover {
    width: 400px !important;
    max-width: 400px !important;
    .note-input {
        border: none;
    }

    .note-list {
        max-height: 180px;
        overflow: auto;
    }

    .mat-icon {
        vertical-align: middle;
        height: auto !important;
    }
    .disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}

.hover-weight:hover {
    font-weight: 600;
}

.text-box {
    border: 2px solid #323f50;
    border-radius: 4px;
    padding: 2px 4px;
    width: 100px;
    font-size: 10px;
    color: #000000;
}

.table-container {
    // min-height: 60vh;
    // height: calc(100vh - 248px);
    height: calc(100vh - 264px);
    overflow: auto;

    &.trip-roster {
        height: calc(100vh - 265px);
    }

    &.short {
        height: calc(100vh - 212px) !important;
    }
}

.touch-scrollbar {
    &::-webkit-scrollbar {
        width: 8px !important;
        height: 12px !important;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
    }
}

.sticky-shadow {
    position: sticky;
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    transition-duration: 0.4s;
}

// Move it to settingheader component once it's approved for all tables.
.setting-header {
    padding-top: 15px;
}

.bottom-search {
    opacity: 0.5;
    width: 150px;
}

.border-color-orange,
.border-color-orange:hover {
    border-color: #ab690778;
}

.secondary_contacts,
.preferred_driver {
    z-index: 999 !important;
    cursor: default;
    .contacts,
    .drivers {
        // max-height: 250px;
        max-height: 200px;
        overflow: auto;
    }

    mat-icon {
        height: 19px;
    }
}

.preferred_driver {
    margin-top: 6px;
}

.touch-list-item:hover {
    background-color: #f5f4f4;
}

.reverse-dropdown-menu {
    .dropdown-toggle::before {
        display: inline-block;
        margin-right: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

    .dropdown-toggle::after {
        display: unset !important;
        margin-left: unset !important;
        vertical-align: unset !important;
        content: unset !important;
        border-top: unset !important;
        border-right: unset !important;
        border-bottom: unset !important;
        border-left: unset !important;
    }
}

.no-caret {
    .dropdown-toggle::after {
        display: unset !important;
        margin-left: unset !important;
        vertical-align: unset !important;
        content: unset !important;
        border-top: unset !important;
        border-right: unset !important;
        border-bottom: unset !important;
        border-left: unset !important;
    }
}

mat-checkbox label {
    margin-bottom: 0 !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $hover-color !important;
}

.op-7 {
    opacity: 0.7;
}

.op-5 {
    opacity: 0.5;
}

.hover-shadow {
    padding: 4px;
    transition-duration: 0.2s;
    border-radius: 4px;

    &.rounded {
        border-radius: 50% !important;
    }

    &:hover {
        background-color: white;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
}

.hover-text-blue:hover {
    color: $dark-blue-color !important;
}

.hover-text-highlight:hover {
    color: $dark-blue-color !important;
    font-weight: 600;
}

.hover-bold:hover {
    font-weight: 600 !important;
}

// .touch-tabs .tab {
//     color: #99a6b7;
//     font-size: 13px;
//     padding-bottom: 10px;
//     cursor: pointer;

//     &.active,
//     &:hover {
//         color: $hover-color;
//         font-weight: 600;
//     }

//     &.active {
//         border-bottom: 2px solid $hover-color;
//     }
// }

.touch-tabs .tab {
    color: #99a6b7;
    font-size: 13px;
    padding-bottom: 15px;
    cursor: pointer;
    transition: 0.15s;
    position: relative;
    min-width: fit-content;

    &.active {
        color: $hover-color;
        font-weight: 600;
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;

        &::after {
            width: 100%;
        }
    }

    // &.active {
    //     border-bottom: 2px solid $hover-color;
    // }

    &::after {
        content: '';
        background: #2c71c3;
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 0;
        height: 2px;
        transition: 0.8s;
        border-radius: 4px;
    }

    &:hover {
        background-color: transparent !important;
        color: #2c71c3 !important;
        font-weight: 600 !important;

        &::after {
            width: 100%;
        }
    }
}

.tab-view-mobile {
    .touch-tabs .tab {
        font-size: 18px !important;
        color: #334d6e;
        padding-bottom: 15px;
        cursor: pointer;
        transition: 0.15s;
        position: relative;
        min-width: fit-content;

        &.active {
            color: $hover-color;
            font-weight: 600;
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;

            &::after {
                width: 100%;
            }
        }
        &::after {
            content: '';
            background: #1f8dc6;
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 0;
            height: 3px;
            transition: 0.8s;
            border-radius: 4px;
        }

        &:hover {
            background-color: transparent !important;
            color: #2c71c3;
            font-weight: 600 !important;

            &::after {
                width: 100%;
            }
        }
    }
}

.z-index-1000 {
    z-index: 1000 !important;
}

// $pulse-names: green orange red yellow white;
// $pulse-colors: #fff;

.pulse {
    // animation-iteration-count: infinite;
    // animation-duration: 1s;
    // animation-timing-function: ease-in-out;
    // animation-name: pulse-motion;
    opacity: 0.4;
    animation: pulse-motion 1s linear infinite alternate;
}
@keyframes pulse-motion {
    // 0%,
    // 100% {
    //     background-color: $pulse-colors;
    // }
    // 50% {
    //     // opacity: 0.4;
    //     background-color: darken($pulse-colors, lightness($pulse-colors) / 3.9);
    // }

    0% {
        background-color: hsl(0, 0%, 80%);
    }

    100% {
        background-color: hsl(0, 00%, 95%);
    }
}

.sticky-loading-box {
    background-color: #99a6b7;
    color: white;
    font-weight: 600;
    animation: sticky-pulse 0.8s linear infinite alternate;
}

@keyframes sticky-pulse {
    0% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

.blink-text {
    animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
    // 0% { opacity: 1; }
    50% {
        opacity: 0.5;
    }
    // 100% { opacity: 1; }
}

.custom-marker {
    position: absolute;
    z-index: 100000000;
    .hand-banner {
        min-width: 160px;
        width: 160px;
        position: relative;
    }

    .rectangle {
        padding: 8px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        img {
            height: 30px;
            width: 30px;
        }
    }

    .pipe {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        width: 6px;
        height: 30px;
        background-color: #333333;
        border-radius: 0px 0px 10px 10px;
    }
}

.mobile-icon-btn {
    padding: 6px;
    background: #adadad40;
    // border-radius: 50%;
    transition-duration: 0.2s;
    text-align: center;

    &.round {
        border-radius: 50%;
    }

    &:hover {
        box-shadow: 0px 4px 4px 0px #00000040;
    }

    &.active {
        background: #84ccd6c4;
    }
}

.no-scrollbar {
    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
}

.addbank-intl {
    width: 230px !important;
}

.fare-estimate-border {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

select[name='recalculate'] .value-regular {
    font-weight: bold;
    color: #334d6e;
}

select#mySelect option {
    font-weight: bold;
    color: #334d6e; /* Change the color to your desired option label color */
}

.fa-chevron-down {
    margin-left: -20px;
    color: #6c757d;
}
.re-calculate {
    font-size: 15px !important;
}

.borderless-input {
    .iti {
        &.input-box {
            > input {
                border: none;
                font-size: 16px;
                color: #828282;
                + label.ng-star-inserted {
                    font-size: 16px;
                    margin-bottom: 0.3rem;
                    margin-top: 0.2rem;
                    color: #828282;
                }
            }
        }
    }
}
.error-label {
    .borderless-input {
        .iti {
            &.input-box {
                > input {
                    border: none;
                    + label.ng-star-inserted {
                        color: red;
                    }
                }
            }
        }
    }
}

.otherOption {
    color: #334d6e;
    font-weight: bold;
    display: flex;
    font-size: 13px;
    margin-bottom: 15px;
    .span-arrow {
        display: flex;
        align-items: baseline;
    }
}
.iphone-bg {
    background-color: white !important;
}

.custom-go-prepaid-icon {
    .prepaid-label {
        padding: 0px !important;
        color: #828282 !important;
        background-color: transparent !important;
    }
    .va-middle {
        display: none;
    }
}

.broker-status-search {
    border: 1px solid #c9c9c9cc;
    border-radius: 6px;
    padding: 2px 10px;
    width: 150px;
    .dropdown-toggle {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #334d6e;
        opacity: 0.5;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .broker-value {
        color: white;
        background-color: #929faf;
        border-radius: 4px;
        text-transform: uppercase;
        padding: 3px;
        width: 85px;
        text-align: center;
    }

    .touch-border-frame {
        padding-left: 0 !important;
    }
    .dropdown-search {
        position: absolute;
        right: 7px;
        top: 7px;
        opacity: 0.5 !important;
    }
}
.ride-broker-value {
    text-transform: uppercase;
}
.mas-server-error {
    display: flex;
    gap: 5px;
}
.ride-broker-label {
    color: #929faf;
}

.tlc-image img {
    height: 16px !important;
}
